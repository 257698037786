import React from "react";

function Playlists() {
    return (
        <div>
            <h1>Playlists section goes here!</h1>
        </div>
    )
}

export default Playlists;