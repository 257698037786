import React from "react";

function StemPlayer() {
    return (
        <div>
            <h1>Stem Player section goes here!</h1>
        </div>
    )
}

export default StemPlayer;