import React from "react";

function Artists() {
    return (
        <div>
            <h1>Artists section goes here!</h1>
        </div>
    )
}

export default Artists;