import React from "react";

function About() {
    return (
        <div className="w-1/2 font-semibold">
            <p>Extremely Pure is a Brooklyn-based record label founded in 2022 by Carrie and Matt Wagner of Silver Liz. </p>
            <br></br>
            <p>We accept demo submissions. </p>
            <p>Please send demos or other inquiries to <a href="mailto:extremelypurerecords@gmail.com" className="hover:underline">extremelypurerecords@gmail.com</a></p>
        </div>
    )
}

export default About;